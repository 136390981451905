import {
  fetchChangeAccountBySession,
  fetchRemoveSession,
  fetchRemoveCurrentAccountBySession,
  fetchActiveAccounts,
  fetchRemoveOneAccount,
} from 'domains/user/actions';
import { useActiveAccounts } from 'domains/user/model/selectors';
import { ResponseUser, TActiveAccountsInfo } from 'domains/user/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';

interface IChangeAccountProps {
  user: ResponseUser | null;
  logOut: () => void;
}

interface IReturnData {
  activeAccounts: TActiveAccountsInfo[] | null;
  isLoading: boolean;
  handleChangeAccount: (email: string) => void;
  handleExitAll: () => void;
  handleExitCurrentAccount: (email: string | undefined) => void;
  handleRemoveOneAccount: (email: string) => void;
}

export const useChangeAccount = ({
  user,
  logOut,
}: IChangeAccountProps): IReturnData => {
  const dispatch = useDispatchApp();
  let { data: accounts } = useActiveAccounts();
  const token = localStorage.getItem('$token');
  const { session_token } = token && JSON.parse(token);
  const domain = window.location.host;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeAccounts, setActiveAccounts] = useState<
    TActiveAccountsInfo[] | null
  >(null);

  const handleChangeAccount = useCallback(
    (email: string) => {
      dispatch(
        fetchChangeAccountBySession({
          email,
          session_token,
          domain
        }),
      );
    },
    [dispatch, session_token, domain],
  );

  const handleExitAll = useCallback(() => {
    if (session_token && activeAccounts && activeAccounts.length) {
      dispatch(fetchRemoveSession({ session_token, logOut }));
    } else {
      logOut();
    }
  }, [dispatch, session_token, logOut, activeAccounts]);

  const handleExitCurrentAccount = useCallback(
    (email: string | undefined) => {
      if (session_token && activeAccounts && activeAccounts.length && email) {
        dispatch(
          fetchRemoveCurrentAccountBySession({
            email,
            session_token,
            domain
          }),
        );
      } else if (session_token && logOut) {
        dispatch(fetchRemoveSession({ session_token, logOut }));
        logOut();
      } else {
        logOut();
      }
    },
    [dispatch, session_token, activeAccounts, logOut, domain],
  );

  const handleRemoveOneAccount = (email: string) => {
    dispatch(
      fetchRemoveOneAccount({
        email,
        session_token,
        callback: {
          setIsLoading,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchActiveAccounts({
        session_token,
        callback: {
          setIsLoading,
        },
      }),
    );
  }, [session_token]);

  useEffect(() => {
    if (accounts) {
      accounts = accounts.filter((acc) => acc.email !== user?.email);
      setActiveAccounts(accounts);
    }
  }, [accounts, user?.email]);

  return {
    activeAccounts,
    isLoading,
    handleChangeAccount,
    handleExitAll,
    handleExitCurrentAccount,
    handleRemoveOneAccount
  };
};
